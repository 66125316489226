/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.tag-selector-wrap {
  .dropdown-menu {
    min-width: 15rem;
    max-height: 178px;
    overflow-y: auto;
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown-item.active {
    color: var(--bs-body-color);
    background-color: var(--an-invite-answer-item-active);
  }
  .hover-hand:hover {
    cursor: pointer;
  }

  .a-placeholder {
    color: var(--bs-secondary-color);
  }

  .a-input {
    padding: 0;
    background: transparent !important;
    box-shadow: none;
    border: none;
    min-width: 60px;
  }
  .a-input:focus {
    box-shadow: none !important;
    border: none;
  }

  .a-input-width {
    display: inline-block;
    position: absolute;
    height: 0;
    overflow: hidden;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .a-placeholder-width {
    display: inline-block;
    position: absolute;
    height: 0;
    overflow: hidden;
    max-width: calc(100% - 40px);
  }
}

.tag-selector-wrap--focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  outline: 0;
}
